import React, { FC, useEffect, useMemo, useRef, useState } from 'react';

import { CheckOutlined, LockOutlined, MailOutlined, PhoneOutlined, ShopOutlined } from '@ant-design/icons';

import { Input as AInput, Col, Form, Row, TimePicker } from 'antd';
import dayjs from 'dayjs';
import * as Sentry from '@sentry/browser';
import { toCityId, toDistrictId, toWardId } from 'components/LocationFormSection/messages';
import Select, { ArrowDown, SelectItem, StyledSelect } from 'components/Select/Select';

import TrackingButton from 'components/TrackingComponents/Button';
import Input from 'components/TrackingComponents/Input';
import { ContactUsForm } from 'containers/ContactUsPage/types';
import messages from 'containers/SignupPage/messages';
import titleMessages from 'containers/ContactUsPage/messages';
import { get, keys } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import styled from 'styled-components';
import translations from 'translations';
import enLocations from 'translations/en/locations';
import media from 'utils/mediaStyle';
import utilsMessages from 'utils/messages';
import { checkEmailRegex } from 'utils/utilities';
import PageHeader from '../PageHeader/PageHeader';
import topBg from './top-bg.png';
import VATInvoiceFormSection from './VATInvoiceFormSection';
import Address from '../Address';
import businessData from 'components/ContactUsForm/businessData';
import { SalesPic } from 'types/schema';

const AntdInput = styled(AInput)<any>``;
const AntdPassword = styled(AInput.Password)<any>``;

const Container = styled.section`
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 8pt;
  flex: 1;
  max-width: 720px;
  background-image: url(${topBg});
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 8pt;
  ${media.md`
    padding: 30pt 90pt;
  `};
  p {
    line-height: normal;
    margin: 8px 0;
  }
`;
const PasswordValidationContainer = styled.div`
  margin-top: 4pt;
  i {
    margin-right: 6pt;
  }
`;

const FormItem = styled(Form.Item)`
  margin-bottom: 12px !important;
  border-radius: 8px !important;
`;

const TitleSection = styled.h4`
  font-weight: 500;
  font-size: 20px;
  color: #808289;
  line-height: 22px;
  margin: 6px 0 14px;
`;

export type CustomerInfo = ContactUsForm & {
  provinceId: string;
  districtId: string;
  wardId: string;
  roleAtBusiness: string;
  addressNo: string;
  acquisitionSource: string;
  buyerCustomerContactRequestId: string;
  createdBy: string;
};

interface SignupFormProps extends RouteComponentProps {
  onSubmit: (data: any) => void;
  onCheckEmail: (data: any) => void;
  isCheckingEmail: boolean;
  validationEmailMessage: string;
  customerInfo: CustomerInfo;
  listSalesPIC: SalesPic[];
  loading?: boolean;
}
const SignupForm: FC<SignupFormProps> = (props) => {
  const { isCheckingEmail, validationEmailMessage, onCheckEmail, customerInfo, onSubmit, listSalesPIC, loading } =
    props;
  const [form] = Form.useForm();
  const watchBusinessType = Form.useWatch('businessType', form);

  const [checkValidPassword, setCheckValidPassword] = useState({
    validSpace: false,
    validNumber: false,
    validLength: false,
    showPopover: false,
  });
  const [enabledVATInvoiceKey, setEnabledVATInvoiceKey] = useState(true);
  const emailValidationCallback = useRef<any>(null);
  const businessTypeData = useMemo(() => {
    return businessData.businessTypes.map((item) => ({
      label: item.name,
      value: item.value,
    }));
  }, []);

  const businessCategoriesData = useMemo(() => {
    return (
      businessData?.[form.getFieldValue('businessType')]?.map((item) => ({
        label: item.name,
        value: item.value,
      })) ?? []
    );
  }, [watchBusinessType]);

  const salesPicData = useMemo(() => {
    return (
      listSalesPIC?.map((item) => ({
        label: item?.name,
        value: item?.id,
      })) || []
    );
  }, [listSalesPIC]);

  const onChangeSalePic = (value) => {
    const salePic = listSalesPIC?.find((item) => item.id === value);
    form.setFieldsValue({
      salePhone: salePic?.phoneNumber,
    });
  };

  const checkEmail = (fieldName) => () => {
    const email = form.getFieldValue(fieldName);
    return Promise.resolve().then(() => {
      if (!checkEmailRegex.test(String(email).toLowerCase())) {
        return;
      }
      return new Promise((resolve, reject) => {
        emailValidationCallback.current = {
          resolve: resolve,
          reject: reject,
        };
        onCheckEmail(email);
      });
    });
  };

  useEffect(() => {
    if (!isCheckingEmail && emailValidationCallback.current) {
      const { resolve, reject } = emailValidationCallback.current;
      if (validationEmailMessage) {
        reject(validationEmailMessage);
      }
      emailValidationCallback.current = null;
      resolve();
    }
  }, [isCheckingEmail, validationEmailMessage]);

  const checkPassword = (value: any) => {
    const password = value;
    let error = false;
    let validSpace = true;
    let validNumber = true;
    let validLength = true;
    if (!password) {
      setCheckValidPassword({
        validSpace: false,
        validNumber: false,
        validLength: false,
        showPopover: false,
      });
      return Promise.reject();
    }
    if (password.indexOf(' ') > -1) {
      error = true;
      validSpace = false;
    }
    if (!password.match(/\d/)) {
      error = true;
      validNumber = false;
    }
    if (password.length < 8) {
      error = true;
      validLength = false;
    }
    if (error) {
      setCheckValidPassword({
        validSpace: validSpace,
        validNumber: validNumber,
        validLength: validLength,
        showPopover: true,
      });
      return Promise.reject();
    }
    setCheckValidPassword({
      validSpace: validSpace,
      validNumber: validNumber,
      validLength: validLength,
      showPopover: false,
    });
    return Promise.resolve();
  };

  const checkConfirmPassword = async () => {
    const password = form.getFieldValue('password');
    const confirmPassword = form.getFieldValue('confirmPassword');
    if (confirmPassword && password !== confirmPassword) {
      return Promise.reject(translations(messages.confirmPasswordNotCorrect));
    }
    return Promise.resolve();
  };

  const getDistrictsByCity = (city: string): SelectItem[] => {
    return keys(get(enLocations, `${city}.districts`, {})).map((district) => {
      return {
        value: district.trim(),
        label: translations(toDistrictId(city, district)),
      };
    });
  };

  const getWardsByCityAndDistrict = (city: string, district: string): SelectItem[] => {
    return keys(get(enLocations, `${city}.districts.${district}.wards`, {})).map((ward) => {
      return {
        value: ward.trim(),
        label: translations(toWardId(city, district, ward)),
      };
    });
  };

  const resetDistrictAndWard = () =>
    form?.setFieldsValue({
      redInvoiceInfo: {
        location: {
          district: undefined,
          ward: undefined,
        },
      },
    });

  const resetWard = () => form.setFieldsValue({ redInvoiceInfo: { location: { ward: undefined } } });

  const cities: SelectItem[] = useMemo(
    () =>
      keys(enLocations).map((city) => ({
        value: city.trim(),
        label: translations(toCityId(city)),
      })),
    [],
  );
  return (
    <Container>
      <PageHeader size="large">
        <FormattedMessage {...messages.header} />
      </PageHeader>
      <Form
        form={form}
        onFinish={(values) => {
          const newValues = {
            ...values,
            acquisitionSource: customerInfo.acquisitionSource,
            buyerCustomerContactRequestId: Number(customerInfo.buyerCustomerContactRequestId),
            workingHoursStart: dayjs(values?.hourOfOperation[0]).format('HH:mm'),
            workingHoursEnd: dayjs(values?.hourOfOperation[1]).format('HH:mm'),
            branchNumber: Number(values?.branchNumber),
            createdBy: customerInfo.createdBy
          };
          delete newValues?.hourOfOperation;
          delete newValues?.salePhone;

          if (newValues?.redInvoiceInfo) {
            delete newValues?.redInvoiceInfo?.cityId;
            delete newValues?.redInvoiceInfo?.districtId;
            delete newValues?.redInvoiceInfo?.addressNo;
          }

          return onSubmit(newValues);
        }}
        onFinishFailed={(err) => Sentry.captureException(err, { level: Sentry.Severity.Warning })}
        className="login-form"
        layout="vertical"
        size="large"
      >
        <TitleSection>
          <FormattedMessage {...messages.accountLogin} />
        </TitleSection>
        <FormattedMessage {...utilsMessages.fieldEmail}>
          {(fieldEmail) => (
            <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: 'Email' }}>
              {(fieldRequired) => (
                <FormItem
                  name="email"
                  initialValue={customerInfo.email}
                  colon={false}
                  required={true}
                  label="Email"
                  hasFeedback
                  rules={[
                    {
                      type: 'email',
                      message: fieldEmail,
                    },
                    { required: true, message: fieldRequired },
                    {
                      validator: checkEmail('email'),
                    },
                  ]}
                  normalize={(value: string) => value && value.replace(/\s/g, '')}
                >
                  <Input
                    InputComponent={AntdInput}
                    prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder="Email"
                    autoComplete="username"
                    trackingCategory="Signup Form | Input"
                    trackingAction="Enter Email"
                  />
                </FormItem>
              )}
            </FormattedMessage>
          )}
        </FormattedMessage>
        <FormattedMessage {...utilsMessages.password}>
          {(password) => (
            <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: password }}>
              {(fieldRequired) => (
                <>
                  <FormItem
                    name="password"
                    colon={false}
                    required={true}
                    label={password}
                    rules={[
                      {
                        required: true,
                        message: fieldRequired,
                      },
                      {
                        validator: (_, value) => checkPassword(value),
                      },
                      {
                        validator: (_, value) => checkConfirmPassword(),
                      },
                    ]}
                  >
                    <Input
                      InputComponent={AntdPassword}
                      prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                      type="password"
                      placeholder={password}
                      autoComplete="new-password"
                      trackingCategory="Signup Form | Input"
                      trackingAction="Enter Password"
                    />
                  </FormItem>
                  <PasswordValidationContainer>
                    <div>
                      <CheckOutlined style={{ color: checkValidPassword.validLength ? '#40A9FF' : '#BFBFBF' }} />
                      <FormattedMessage {...messages.atLeast8Char} />
                    </div>
                    <div>
                      <CheckOutlined style={{ color: checkValidPassword.validNumber ? '#40A9FF' : '#BFBFBF' }} />
                      <FormattedMessage {...messages.atLeast1Num} />
                    </div>
                    <div>
                      <CheckOutlined style={{ color: checkValidPassword.validSpace ? '#40A9FF' : '#BFBFBF' }} />
                      <FormattedMessage {...messages.noSpace} />
                    </div>
                  </PasswordValidationContainer>
                </>
              )}
            </FormattedMessage>
          )}
        </FormattedMessage>
        <FormattedMessage {...utilsMessages.passwordConfirmation}>
          {(passwordConfirmation) => (
            <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: passwordConfirmation }}>
              {(fieldRequired) => (
                <FormItem
                  name="confirmPassword"
                  colon={false}
                  required={true}
                  label={passwordConfirmation}
                  rules={[
                    {
                      required: true,
                      message: fieldRequired,
                    },
                    {
                      validator: (_, value) => checkConfirmPassword(),
                    },
                  ]}
                >
                  <Input
                    InputComponent={AntdPassword}
                    prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="password"
                    autoComplete="off"
                    placeholder={passwordConfirmation}
                    trackingCategory="Signin Form | Input"
                    trackingAction="Confirm Password"
                  />
                </FormItem>
              )}
            </FormattedMessage>
          )}
        </FormattedMessage>
        <TitleSection>
          <FormattedMessage {...titleMessages.businessInfo} />
        </TitleSection>
        <FormattedMessage {...utilsMessages.businessName}>
          {(companyName: string) => (
            <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: companyName }}>
              {(fieldRequired: string) => (
                <FormItem
                  name="companyName"
                  colon={false}
                  initialValue={customerInfo.companyName}
                  required={true}
                  label={companyName}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: fieldRequired,
                    },
                  ]}
                >
                  <Input
                    InputComponent={AntdInput}
                    prefix={<ShopOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder={companyName}
                    trackingCategory="Signup Form | Input"
                    trackingAction="Enter Company Name"
                  />
                </FormItem>
              )}
            </FormattedMessage>
          )}
        </FormattedMessage>
        <Row gutter={12}>
          <Col span={12}>
            <FormattedMessage {...utilsMessages.businessType}>
              {(companyName: string) => (
                <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: companyName }}>
                  {(fieldRequired: string) => (
                    <FormattedMessage {...utilsMessages.businessTypePlaceHolder}>
                      {(businessTypePlaceHolder) => (
                        <FormItem
                          name="businessType"
                          colon={false}
                          required={true}
                          label={companyName}
                          hasFeedback
                          rules={[
                            {
                              required: true,
                              message: fieldRequired,
                            },
                          ]}
                        >
                          <Select
                            items={businessTypeData}
                            placeholder={businessTypePlaceHolder}
                            onChange={() => form.setFieldValue('businessCategory', undefined)}
                          />
                        </FormItem>
                      )}
                    </FormattedMessage>
                  )}
                </FormattedMessage>
              )}
            </FormattedMessage>
          </Col>
          <Col span={12}>
            <FormattedMessage {...utilsMessages.businessCategory}>
              {(companyName: string) => (
                <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: companyName }}>
                  {(fieldRequired: string) => (
                    <FormattedMessage {...utilsMessages.businessCategoryPlaceHolder}>
                      {(businessCategoryPlaceHolder) => (
                        <FormItem
                          name="businessCategory"
                          initialValue={customerInfo.businessCategory}
                          colon={false}
                          required={true}
                          label={companyName}
                          hasFeedback
                          rules={[
                            {
                              required: true,
                              message: fieldRequired,
                            },
                          ]}
                        >
                          <Select items={businessCategoriesData} placeholder={businessCategoryPlaceHolder} />
                        </FormItem>
                      )}
                    </FormattedMessage>
                  )}
                </FormattedMessage>
              )}
            </FormattedMessage>
          </Col>
        </Row>
        <FormattedMessage {...utilsMessages.linkHubspot}>
          {(linkHubspot: string) => (
            <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: linkHubspot }}>
              {(fieldRequired: string) => (
                <FormattedMessage {...utilsMessages.linkHubspotPlaceHolder}>
                  {(linkHubspotPlaceHolder) => (
                    <FormItem
                      name="linkHubspot"
                      colon={false}
                      required={true}
                      label={linkHubspot}
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: fieldRequired,
                        },
                      ]}
                    >
                      <Input
                        InputComponent={AntdInput}
                        placeholder={linkHubspotPlaceHolder}
                        trackingCategory="Signup Form | Input"
                        trackingAction="Enter link Hubspot"
                      />
                    </FormItem>
                  )}
                </FormattedMessage>
              )}
            </FormattedMessage>
          )}
        </FormattedMessage>
        {/* <FormattedMessage {...utilsMessages.companyWebsite}>
          {(companyWebsite: string) => (
            <FormattedMessage {...utilsMessages.shoppeeFoodValidate}>
              {(fieldValidate: string) => (
                <FormattedMessage {...utilsMessages.companyWebsitePlaceHolder}>
                  {(companyWebsitePlaceHolder) => (
                    <FormItem
                      name="companyWebsite"
                      colon={false}
                      required={false}
                      label={companyWebsite}
                      hasFeedback
                      rules={[
                        () => ({
                          validator: function (_, value) {
                            const regex = /^https:\/\/shopeefood\.vn\//;
                            if (!value || regex.test(value)) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error(fieldValidate));
                          },
                        }),
                      ]}
                    >
                      <Input
                        InputComponent={AntdInput}
                        placeholder={companyWebsitePlaceHolder}
                        trackingCategory="Signup Form | Input"
                        trackingAction="Enter ShopeeFood"
                      />
                    </FormItem>
                  )}
                </FormattedMessage>
              )}
            </FormattedMessage>
          )}
        </FormattedMessage> */}
        <Row gutter={12}>
          <Col span={12}>
            <FormattedMessage {...utilsMessages.numberOfBranches}>
              {(numberOfBranches: string) => (
                <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: numberOfBranches }}>
                  {(fieldRequired: string) => (
                    <FormattedMessage {...utilsMessages.numberOfBranchesPlaceHolder}>
                      {(numberOfBranchesPlaceHolder) => (
                        <FormItem
                          name="branchNumber"
                          colon={false}
                          required={true}
                          label={numberOfBranches}
                          hasFeedback
                          rules={[
                            {
                              required: true,
                              message: fieldRequired,
                            },
                          ]}
                          getValueFromEvent={(event) => {
                            let value = event.target.value.replace(/[^0-9]/g, '');
                            value = value.match(/^0+$/) ? '0' : value.replace(/^0+/, '');
                            return value;
                          }}
                        >
                          <Input
                            InputComponent={AntdInput}
                            placeholder={numberOfBranchesPlaceHolder}
                            trackingCategory="Signup Form | Input"
                            trackingAction="Enter Number of Branches"
                          />
                        </FormItem>
                      )}
                    </FormattedMessage>
                  )}
                </FormattedMessage>
              )}
            </FormattedMessage>
          </Col>
          <Col span={12}>
            <FormattedMessage {...utilsMessages.hourOfOperation}>
              {(hourOfOperation: string) => (
                <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: hourOfOperation }}>
                  {(fieldRequired: string) => (
                    <FormattedMessage {...utilsMessages.hourOfOperationPlaceHolderOpen}>
                      {(hourOfOperationPlaceHolderOpen) => (
                        <FormattedMessage {...utilsMessages.hourOfOperationPlaceHolderClose}>
                          {(hourOfOperationPlaceHolderClose) => (
                            <FormItem
                              name="hourOfOperation"
                              colon={false}
                              required={true}
                              label={hourOfOperation}
                              hasFeedback
                              rules={[
                                {
                                  required: true,
                                  message: fieldRequired,
                                },
                              ]}
                            >
                              <TimePicker.RangePicker
                                placeholder={[
                                  hourOfOperationPlaceHolderOpen as string,
                                  hourOfOperationPlaceHolderClose as string,
                                ]}
                                format="HH:mm"
                              />
                            </FormItem>
                          )}
                        </FormattedMessage>
                      )}
                    </FormattedMessage>
                  )}
                </FormattedMessage>
              )}
            </FormattedMessage>
          </Col>
        </Row>

        <FormattedMessage {...utilsMessages.team}>
          {(team: string) => (
            <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: team }}>
              {(fieldRequired: string) => (
                <FormattedMessage {...utilsMessages.teamPlaceHolder}>
                  {(teamPlaceHolder) => (
                    <FormItem
                      name="businessTeam"
                      colon={false}
                      required={true}
                      label={team}
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: fieldRequired,
                        },
                      ]}
                    >
                      <Select items={businessData.team} placeholder={teamPlaceHolder} />
                    </FormItem>
                  )}
                </FormattedMessage>
              )}
            </FormattedMessage>
          )}
        </FormattedMessage>

        <Row gutter={12}>
          <Col span={12}>
            <FormattedMessage {...utilsMessages.salePIC}>
              {(salePIC: string) => (
                <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: salePIC }}>
                  {(fieldRequired: string) => (
                    <FormattedMessage {...utilsMessages.salePICPlaceHolder}>
                      {(salePICPlaceHolder) => (
                        <FormItem
                          name="salePicId"
                          colon={false}
                          required={true}
                          label={salePIC}
                          hasFeedback
                          rules={[
                            {
                              required: true,
                              message: fieldRequired,
                            },
                          ]}
                        >
                          <StyledSelect
                            suffixIcon={<ArrowDown />}
                            options={salesPicData}
                            showSearch
                            optionFilterProp="label"
                            filterOption={true}
                            placeholder={salePICPlaceHolder}
                            onChange={onChangeSalePic}
                          />
                        </FormItem>
                      )}
                    </FormattedMessage>
                  )}
                </FormattedMessage>
              )}
            </FormattedMessage>
          </Col>
          <Col span={12}>
            <FormattedMessage {...utilsMessages.salePhone}>
              {(salePhone: string) => (
                <FormItem name="salePhone" colon={false} required={true} label={salePhone}>
                  <Input disabled InputComponent={AntdInput} trackingCategory="Signup Form | Input" />
                </FormItem>
              )}
            </FormattedMessage>
          </Col>
        </Row>
        <TitleSection>
          <FormattedMessage {...titleMessages.businessAddress} />
        </TitleSection>
        <Address
          form={form}
          fieldName={{ city: 'provinceId', district: 'districtId', ward: 'wardId', addressDetail: 'addressNo' }}
          provinceValue={customerInfo?.provinceId ?? ''}
          districtValue={customerInfo?.districtId}
          wardValue={customerInfo?.wardId}
          addressDetailValue={customerInfo?.addressNo}
        />
        <TitleSection>
          <FormattedMessage {...titleMessages.businessRepresentative} />
        </TitleSection>
        <FormattedMessage {...utilsMessages.representativeName}>
          {(representativeName: string) => (
            <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: representativeName }}>
              {(fieldRequired: string) => (
                <FormItem
                  name="buyerRepresentative"
                  initialValue={customerInfo.userName}
                  colon={false}
                  required={true}
                  label={representativeName}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: fieldRequired,
                    },
                  ]}
                >
                  <Input
                    InputComponent={AntdInput}
                    prefix={<ShopOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder={representativeName}
                    trackingCategory="Signup Form | Input"
                    trackingAction="Enter Representative"
                  />
                </FormItem>
              )}
            </FormattedMessage>
          )}
        </FormattedMessage>
        <FormattedMessage {...utilsMessages.phone}>
          {(phone: string) => (
            <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: phone }}>
              {(fieldRequired) => (
                <FormattedMessage {...utilsMessages.validatePhone}>
                  {(fieldValidate) => (
                    <FormItem
                      name="telephone"
                      initialValue={customerInfo.phoneNumber}
                      colon={false}
                      required={true}
                      label={phone}
                      hasFeedback
                      rules={[
                        { required: true, message: fieldRequired },
                        {
                          validator: (_, value) => {
                            if (!value || (value.length >= 10 && value.length <= 11)) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error(fieldValidate as string));
                          },
                        },
                      ]}
                      getValueFromEvent={(event) => {
                        const value = event.target.value.replace(/[^0-9]/g, '');
                        return value;
                      }}
                    >
                      <Input
                        InputComponent={AntdInput}
                        prefix={<PhoneOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder={phone}
                        autoComplete="phone"
                        trackingCategory="Signup Form | Input"
                        trackingAction="Enter Phone"
                      />
                    </FormItem>
                  )}
                </FormattedMessage>
              )}
            </FormattedMessage>
          )}
        </FormattedMessage>
        <FormattedMessage {...utilsMessages.fieldEmail}>
          {(fieldEmail) => (
            <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: 'Email' }}>
              {(fieldRequired) => (
                <FormItem
                  name="buyerRepresentativeEmail"
                  initialValue={customerInfo.email}
                  colon={false}
                  required={true}
                  label="Email"
                  hasFeedback
                  rules={[
                    {
                      type: 'email',
                      message: fieldEmail,
                    },
                    { required: true, message: fieldRequired },
                    // {
                    //   validator: checkEmail('representativeEmail'),
                    // },
                  ]}
                  normalize={(value: string) => value && value.replace(/\s/g, '')}
                >
                  <Input
                    InputComponent={AntdInput}
                    prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder="Email"
                    autoComplete="username"
                    trackingCategory="Signup Form | Input"
                    trackingAction="Enter representative Email"
                  />
                </FormItem>
              )}
            </FormattedMessage>
          )}
        </FormattedMessage>
        <FormattedMessage {...utilsMessages.roleAtBusiness}>
          {(roleAtBusiness: string) => (
            <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: roleAtBusiness }}>
              {(fieldRequired: string) => (
                <FormattedMessage {...utilsMessages.rolePlaceHolder}>
                  {(rolePlaceHolder) => (
                    <FormItem
                      name="roleAtBusiness"
                      initialValue={customerInfo.roleAtBusiness}
                      colon={false}
                      required={true}
                      label={roleAtBusiness}
                      hasFeedback
                      rules={[
                        {
                          required: false,
                          message: fieldRequired,
                        },
                      ]}
                    >
                      <Select
                        items={businessData.roleAtBusiness.map((item) => ({
                          label: item.name,
                          value: item.value,
                        }))}
                        placeholder={rolePlaceHolder}
                      />
                    </FormItem>
                  )}
                </FormattedMessage>
              )}
            </FormattedMessage>
          )}
        </FormattedMessage>

        <VATInvoiceFormSection
          form={form}
          enabledVATInvoiceKey={enabledVATInvoiceKey}
          onEnableGetVATInvoiceEvent={() => setEnabledVATInvoiceKey(!enabledVATInvoiceKey)}
          resetDistrictAndWard={resetDistrictAndWard}
          getDistrictsByCity={getDistrictsByCity}
          resetWard={resetWard}
          cities={cities}
          getWardsByCityAndDistrict={getWardsByCityAndDistrict}
        />

        <FormItem style={{ marginTop: '6px' }}>
          <TrackingButton
            block
            type="primary"
            htmlType="submit"
            trackingCategory="Signup Form | Button"
            trackingAction="Submit Signup Form"
            loading={loading}
          >
            <FormattedMessage {...utilsMessages.signup} />
          </TrackingButton>
        </FormItem>
        <p>
          <FormattedMessage {...messages.alreadyHasAccount} />{' '}
          <Link to="/signin">
            <FormattedMessage {...messages.signinBang} />
          </Link>
        </p>
      </Form>
    </Container>
  );
};

export default withRouter(SignupForm);
